import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Body, Headline, HoverButton, HoverCard, MiniHeroIcon } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { INTL_PHONE_MASK, Margin, PHONE_MASK, endpoint } from 'src/constants';
import styled from 'styled-components/native';
import { useBookingState } from '../../BookingProvider';
import ConfirmationSection from '../ConfirmationSection';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { isDefinedOrThrowServerError, queryClient } from 'src/utils';
import { BOOKING_STEPS, SUBMISSION_STATE } from '../../BookingProvider/helpers';
import { ProgressBar } from 'react-native-paper';
import { LayoutAnimation } from 'react-native';
import Confetti from 'src/scenes/ToDos/ToDoList/components/Confetti';
import RichTextRenderer from 'src/components/PracticeTextRenderer';
import BookingStateIcon from '../BookingStateIcon';
import moment from 'moment-timezone';
import { SvgProps } from 'react-native-svg';
import { unauthenticatedApiClient } from 'src/utils/axios/unauthenticated';
import { StyledScrollView } from '../shared';
import { mask } from 'react-native-mask-text';
import {
  isFinalState,
  getNextState,
  switchColor,
  switchProgress,
  getPracticeText,
  WRITEBACK_STATUS,
  RequestStatus
} from './helper';

const REFETCH_INTERVAL = moment.duration(5, 'seconds').asMilliseconds();
const MIN_STATE_DURATION = moment.duration(1, 'seconds').asMilliseconds();

const BookingStatus: React.FC = () => {
  const { colors } = useAppTheme();
  const {
    values,
    errors,
    practiceInfo,
    goToStep,
    isSubmitting,
    requestSetting,
    setFieldValue,
    openingsQuery,
    validateField
  } = useBookingState();
  const { t } = useTranslation('onlineBooking');

  const { data, isError: isApiError } = useQuery({
    queryKey: [QueryKeys.WRITEBACK_STATUS, values.requestId],
    queryFn: async () => {
      const response = await unauthenticatedApiClient.get<RequestStatus>(
        endpoint('REQUEST_STATUS'),
        {
          params: {
            request_id: values.requestId
          }
        }
      );
      const data = isDefinedOrThrowServerError(response?.data);

      if (data.writeback_status === WRITEBACK_STATUS.FAILURE) {
        await openingsQuery.refetch();
        await validateField('appointmentInfo');
      }

      return data;
    },
    enabled: !isSubmitting && !!values.requestId && practiceInfo.onlineBooking,
    refetchInterval: (data) => (isFinalState(data?.writeback_status) ? false : REFETCH_INTERVAL)
  });

  const [state, setStringsKey] = React.useState(SUBMISSION_STATE.SUBMITTING);

  const setNextState = useMemo(
    () =>
      debounce(
        (state: SUBMISSION_STATE) =>
          setStringsKey((prev) => {
            if (prev === state) return prev;
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            return state;
          }),
        MIN_STATE_DURATION,
        {
          leading: false,
          trailing: true
        }
      ),
    []
  );

  useEffect(() => {
    const nextState = getNextState({
      isSubmitting,
      errors,
      isError: isApiError,
      data,
      values,
      practiceInfo
    });
    if (state !== nextState) {
      setNextState(nextState);
    }
  }, [
    errors,
    data,
    isApiError,
    isSubmitting,
    setNextState,
    values,
    values.clientInfo.clientId,
    state,
    practiceInfo
  ]);

  const isError = state === SUBMISSION_STATE.UNAVAILABLE;

  const Icon = useCallback(
    (props: SvgProps) => <BookingStateIcon state={state} {...props} />,
    [state]
  );

  return (
    <StyledScrollView>
      {state === SUBMISSION_STATE.SCHEDULED && <StyledConfetti />}
      <StyledCard>
        <MiniHeroIcon icon={Icon} color={switchColor(state, colors)} />
        <CenteredText as={Headline}>{t(`status.${state}.title`)}</CenteredText>

        <ProgressBar
          indeterminate={switchProgress(state) === undefined}
          color={switchColor(state, colors)}
          progress={switchProgress(state)}
        />

        <RichTextRenderer
          fallback={
            <CenteredText>
              {t(`status.${state}.description`, {
                email: values.clientInfo.emailAddress,
                phone: mask(practiceInfo.phoneNumber, [PHONE_MASK, INTL_PHONE_MASK])
              })}
            </CenteredText>
          }
        >
          {getPracticeText(state, requestSetting)}
        </RichTextRenderer>

        {isError && data?.writeback_status === WRITEBACK_STATUS.FAILURE && (
          <HoverButton
            buttonColor={colors.error}
            mode='contained'
            onPress={async () => {
              await setFieldValue('requestId', undefined);
              await queryClient.invalidateQueries([QueryKeys.ON_DEMAND_SYNC]);
              await queryClient.invalidateQueries([QueryKeys.ON_DEMAND_STATUS]);
              await queryClient.invalidateQueries([QueryKeys.SCHEDULE_OPENINGS]);
              goToStep(BOOKING_STEPS.SELECT_APPOINTMENT);
            }}
          >
            {t(`status.UNAVAILABLE.retry`)}
          </HoverButton>
        )}
      </StyledCard>
      <ConfirmationSection
        section={'appointmentInfo'}
        values={values}
        errors={errors}
        practiceInfo={practiceInfo}
        disableEditing
      />
    </StyledScrollView>
  );
};

export default BookingStatus;

const StyledCard = styled(HoverCard)`
  padding: ${Margin.ExtraLarge}px;
  gap: ${Margin.Large}px;
`;

const CenteredText = styled(Body)`
  text-align: center;
`;

const StyledConfetti = styled(Confetti).attrs({
  preserveAspectRatio: 'xMidYMid slice'
})`
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
`;
