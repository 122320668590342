import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { AppointmentRequestSettings, BookingFormData, BookingStateType, State } from './model';
import { useFormik } from 'formik';
import { validationSchema } from './validation';
import { ApiPracticeInfo, Client, Openings, Patient, Request } from 'src/interfaces';
import { BOOKING_STEPS } from './helpers';
import { useMutation, useQuery } from 'react-query';
import useOnDemandPoll from './useOnDemandPoll';
import {
  ClientPatientSearchParams,
  QueryKeys,
  clientUnauthenticatedSearch,
  getPatients
} from 'src/api';
import useAppointmentOptions from 'src/hooks/useAppointmentOptions';
import { generateSubmissionValues, getInitialValues, isClientMatch } from './helper';
import { DiscardChangesModal } from 'src/components';
import { isDefined, isDefinedOrThrowServerError, isPatientActive, queryClient } from 'src/utils';
import mapClientPatientSearch from './helpers/mapClientPatientSearch';
import { unauthenticatedApiClient } from 'src/utils/axios/unauthenticated';
import { endpoint, TRACKING_EVENTS } from 'src/constants';
import useFilterAppointmentOptions from './helpers/useFilterAppointmentOptions';
import { trackEvent } from 'src/utils/utmTracking';
import { useTrackVisit } from 'src/hooks';

const BookingContext = createContext<BookingStateType | undefined>(undefined);

export const useBookingState = () => {
  const context = useContext(BookingContext);
  if (context === undefined) {
    throw new Error('useBookingState must be used within a BookingStateProvider');
  }
  return context;
};

export const BookingStateConsumer = BookingContext.Consumer;

interface BookingProviderProps extends PropsWithChildren {
  practiceInfo: ApiPracticeInfo;
  user: Client | undefined;
  requestSetting: AppointmentRequestSettings;
  patient: Patient | undefined;
}

export const BookingProvider: React.FC<BookingProviderProps> = ({
  practiceInfo,
  children,
  user,
  requestSetting,
  patient
}) => {
  const { data: visitData } = useTrackVisit({
    initialEventName: TRACKING_EVENTS.APPOINTMENT_REQUEST_PAGE,
    practiceId: practiceInfo.practiceId,
    sourceId: practiceInfo.sourceId,
    clientId: user?.clientId
  });

  const [bookingState, setBookingState] = useState<State>({
    current: BOOKING_STEPS.CLIENT_INFO,
    visited: { [BOOKING_STEPS.CLIENT_INFO]: true }
  });

  const appointmentOptions = useAppointmentOptions(practiceInfo.practiceId);
  const [lastSubmittedValues, setLastSubmittedValues] = useState<
    Partial<BookingFormData> | undefined
  >();
  const initialValues = useMemo(
    () => getInitialValues(user, appointmentOptions.data, patient, lastSubmittedValues),
    [user, appointmentOptions.data, patient, lastSubmittedValues]
  );

  const { setFieldValue, submitForm, ...formik } = useFormik<BookingFormData>({
    initialValues,
    validateOnChange: false,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldValue }) => {
      trackEvent(TRACKING_EVENTS.APPOINTMENT_REQUEST_SUBMITTED);
      const submissionData = generateSubmissionValues(practiceInfo, values, visitData);
      const response = await unauthenticatedApiClient.post<Request>(endpoint('REQUESTS'), {
        request: submissionData
      });
      const data = isDefinedOrThrowServerError(response.data);
      await setFieldValue('requestId', data.id);
      setLastSubmittedValues({ ...values, requestId: data.id });
      queryClient.setQueryData([QueryKeys.REQUESTS, data.id], data);
    }
  });

  const { onDemandReady } = useOnDemandPoll(practiceInfo);

  const { mutateAsync, ...clientPatientQuery } = useMutation({
    mutationFn: async (args: ClientPatientSearchParams) => {
      if (user && isClientMatch({ practiceInfo, clientInfo: formik.values.clientInfo, user })) {
        const patientData = await queryClient.fetchQuery<Patient[]>([QueryKeys.PATIENT], {
          queryFn: getPatients
        });
        const data = mapClientPatientSearch({
          patients: patientData.filter(isPatientActive),
          clientId: user?.clientId
        });
        return data;
      }
      const data = await clientUnauthenticatedSearch(args);
      if (isDefined(data.clientId)) {
        trackEvent(TRACKING_EVENTS.APPOINTMENT_REQUEST_PAGE_CLIENT_FOUND);
      }
      return mapClientPatientSearch(data);
    }
  });
  useEffect(() => {
    if (clientPatientQuery.isSuccess) {
      void setFieldValue('clientInfo.clientId', clientPatientQuery.data?.clientId);
    }
  }, [clientPatientQuery.data?.clientId, clientPatientQuery.isSuccess, setFieldValue]);

  useEffect(() => {
    if (initialValues.clientInfo.emailAddress) {
      void mutateAsync({
        email: initialValues.clientInfo.emailAddress,
        practiceId: practiceInfo.practiceId,
        phoneNumber: initialValues.clientInfo.phoneNumber
      });
    }
  }, [
    mutateAsync,
    initialValues.clientInfo.emailAddress,
    practiceInfo.practiceId,
    initialValues.clientInfo.phoneNumber
  ]);

  const openingsQuery = useQuery({
    queryFn: async () => {
      await queryClient.invalidateQueries([
        QueryKeys.REQUEST_SETTING,
        practiceInfo?.practiceId,
        'online-booking'
      ]);
      const response = await unauthenticatedApiClient.get<Openings>(
        endpoint('SCHEDULE_OPENINGS', {
          practiceId: practiceInfo.practiceId
        }),
        {
          params: {
            appointment_type_id: formik.values.appointmentInfo.appointmentOption?.value,
            category_id: formik.values.appointmentInfo.columnOption?.categoryId
          }
        }
      );

      return isDefinedOrThrowServerError(response.data);
    },
    queryKey: [
      QueryKeys.SCHEDULE_OPENINGS,
      formik.values.appointmentInfo.appointmentOption?.value,
      formik.values.appointmentInfo.columnOption?.categoryId
    ],
    suspense: false,
    enabled: onDemandReady && !!bookingState.visited[BOOKING_STEPS.SELECT_APPOINTMENT]
  });

  const advance = useCallback(async () => {
    if (bookingState.current === BOOKING_STEPS.CONFIRMATION) {
      void submitForm();
    }
    setBookingState((prev) => {
      if (prev.current === BOOKING_STEPS.STATUS) return prev;
      const next: BOOKING_STEPS = prev.current + 1;
      prev.current = next;
      prev.visited[next] = true;
      return { ...prev };
    });
  }, [bookingState, submitForm]);

  const goBack = useCallback(() => {
    setBookingState(({ visited, current }) => ({ visited, current: current - 1 }));
  }, []);

  const goToStep = useCallback((step: BOOKING_STEPS) => {
    setBookingState((prev) => {
      prev.current = step;
      prev.visited[step] = true;
      return { ...prev };
    });
  }, []);

  useFilterAppointmentOptions({
    openingsQuery,
    appointmentOptions,
    setFieldValue,
    validateField: formik.validateField,
    practiceInfo,
    values: formik.values
  });

  if (!requestSetting || !appointmentOptions) {
    return null;
  }

  return (
    <BookingContext.Provider
      value={{
        advance,
        goBack,
        goToStep,
        onDemandReady,
        practiceInfo,
        requestSetting,
        appointmentOptions,
        clientPatientQuery: { ...clientPatientQuery, mutateAsync },
        openingsQuery,
        bookingState,
        setBookingState,
        ...formik,
        setFieldValue
      }}
    >
      {children}
      <DiscardChangesModal isDirty={formik.dirty} />
    </BookingContext.Provider>
  );
};
