import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getEmployees, QueryKeys } from 'src/api';
import { Employee } from 'src/interfaces';

function useEmployee(): { data: Employee[]; isLoading: boolean };
function useEmployee(employeeId: string): { data: Employee | undefined; isLoading: boolean };
function useEmployee(employeeId?: string) {
  const { data: employees = [], isLoading } = useQuery({
    queryKey: [QueryKeys.EMPLOYEES],
    queryFn: getEmployees
  });

  const data = useMemo(
    () => (employeeId ? employees?.find((e) => e.employeeId === employeeId) : employees),
    [employeeId, employees]
  );

  return {
    data,
    isLoading
  };
}

export default useEmployee;
