import React, { Fragment, useMemo } from 'react';
import { Body, Subheading } from 'src/components';
import { isDefined } from 'src/utils';
import { CONFIRMATION_SECTIONS, VALUE_TRANSFORMS } from '../BookingProvider/helpers';
import EditButton from './EditButton';
import { NoticeContainer, ErrorText } from './shared';
import { BookingFormData, BookingStateType } from '../BookingProvider/model';
import styled, { css } from 'styled-components/native';
import { Margin } from 'src/constants';
import { Weight } from 'src/theme';
import { useTranslation } from 'react-i18next';

interface Props extends Pick<BookingStateType, 'values' | 'errors' | 'practiceInfo'> {
  section: keyof BookingFormData;
  disableEditing?: boolean;
}
const ConfirmationSection: React.FC<Props> = ({
  section,
  values,
  errors,
  practiceInfo,
  disableEditing
}) => {
  const { t } = useTranslation('onlineBooking');
  const { title, fields, bookingStateKey, fieldDisplay, sectionKey } = useMemo(
    () => CONFIRMATION_SECTIONS[section]!,
    [section]
  );

  const labelValues = useMemo(() => {
    const valueSection = values[sectionKey];
    const errorsSection = errors?.[sectionKey];
    return fields
      .map((field) => {
        const value = valueSection?.[field as keyof typeof valueSection];
        const error = errorsSection?.[field as keyof typeof errorsSection];
        return { field, value, error };
      })
      .filter(({ value, error }) => isDefined(value) || error);
  }, [fields, values, errors, sectionKey]);

  return (
    <NoticeContainer>
      <Container fieldDisplay='row'>
        <Subheading>{t(title)}</Subheading>
        {!disableEditing && <EditButton section={bookingStateKey} />}
      </Container>
      <Column>
        {labelValues.map(({ field, value, error }) => {
          if (!isDefined(value) && !error) {
            return null;
          }

          const transformedValue =
            VALUE_TRANSFORMS[field]?.({ values, practiceInfo }) ?? value ?? '';

          return (
            <Fragment key={field}>
              <Container fieldDisplay={fieldDisplay}>
                <SectionTitle fieldDisplay={fieldDisplay}>{t(`form.${field}`)}</SectionTitle>
                {transformedValue &&
                  (typeof transformedValue === 'string' || typeof transformedValue === 'number' ? (
                    <Value>{t(`${transformedValue}`)}</Value>
                  ) : (
                    transformedValue
                  ))}
              </Container>
              {error && <ErrorText>{t(error)}</ErrorText>}
            </Fragment>
          );
        })}
        {!labelValues.length && <Value>{t('common:n/a')}</Value>}
        {errors[sectionKey] && <ErrorText>{t(`errors.${sectionKey}`)}</ErrorText>}
      </Column>
    </NoticeContainer>
  );
};

export default ConfirmationSection;

const Container = styled.View<{ fieldDisplay: 'row' | 'column' }>`
  ${({ fieldDisplay }) => (fieldDisplay === 'row' ? RowStyle : ColumnStyle)}
`;

const RowStyle = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ColumnStyle = css`
  flex-direction: column;
  gap: ${Margin.Medium}px;
`;

const Column = styled.View`
  flex-direction: column;
  gap: ${Margin.Large}px;
`;

const SectionTitle = styled(Body)<{ fieldDisplay: 'row' | 'column' }>`
  color: ${({ theme }) => theme.colors.onSurface};
  ${({ fieldDisplay }) => fieldDisplay === 'column' && `font-weight: ${Weight.BOLD};`}
`;

const Value = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;
